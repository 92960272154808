import { Box, Typography } from "@mui/material";
import React, { useState } from "react";

interface Props {
  title: string;
  Image: string;
  width: string;
  height: string;
  descripcion?: string;
}

const CardModulos: React.FC<Props> = ({ title, Image, width, height, descripcion }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Box
      sx={{
        display: "flex",
        width: width,
        height: height,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "12px",
        background: `url(${Image})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
        overflow: "hidden",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Contenido Principal */}
      <Box
        sx={{
          display: "flex",
          padding: "10px 20px",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "8px",
          flex: "1 0 0",
          alignSelf: "stretch",
          background: "rgba(35, 35, 35, 0.50)",
          position: "relative",
        }}
      >
        <Typography
          sx={{
            alignSelf: "stretch",
            color: "#FFF",
            textAlign: "center",
            fontFamily: "Montserrat",
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "120%",
            transition: "transform 0.5s, opacity 0.0s",
            transform: isHovered ? "translateY(-100%)" : "translateY(0)",
            opacity: isHovered ? 0 : 1,
          }}
        >
          {title}
        </Typography>
      </Box>

      {/* Fondo negro (aparece en hover) */}
      <Box
        sx={{
          display: "flex",
          padding: "0px 10px",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "12px",
          flex: "1 0 0",
          alignSelf: "stretch",
          background: isHovered ? "rgba(35, 35, 35, 0.80)":"",
          position: "absolute", // Asegura que el fondo se superponga al contenido
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1, // Asegura que el fondo esté encima del contenido
        }}
      >
        {/* INFO */}
        <Box
          sx={{
            display: "flex",
            paddingTop: "20px",
            flexDirection: "column",
            alignItems: "center",
            gap: "12px",
            alignSelf: "stretch",
          }}
        >
          <Typography
            sx={{
              color: "#FFF",
              textAlign: "center",
              fontFamily: "Montserrat",
              fontSize: "24px",
              fontWeight: 500,
              lineHeight: "120%",
              transition: "transform 0.5s, opacity 0.0s",
              transform: isHovered ? "translateY(0)" : "translateY(100%)",
              opacity: isHovered ? 1 : 0,
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              color: "#FFF",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontWeight: 300,
              lineHeight: "150%",
              transition: "transform 0.5s, opacity 0.0s",
              transform: isHovered ? "translateY(0)" : "translateY(100%)",
              opacity: isHovered ? 1 : 0,
            }}
          >
            {descripcion}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CardModulos;