import { CssBaseline, ThemeProvider } from "@mui/material";
import { lazy, Suspense, useMemo, useState } from "react";
import getTheme from "../config/theme";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DetallesEquipos from "../content/Equipos/DetallesEquipos";
import InfoRest from "../content/Modulos/InfoRest";
import InfoHotel from "../content/Modulos/InfoHotel";
import InfoBack from "../content/Modulos/InfoBack";
import InfoFact from "../content/Modulos/InfoFact";
import Equipos from "../content/Equipos/Equipos";

const Dashboard = lazy(() => import("../content/Inicio/Layout"));
const Inicio = lazy(() => import("../content/Inicio/Body/Body"));
const QuinesSomos = lazy(() => import("../content/QuiénesSomos/QuinesSomos"));
const Soporte = lazy(() => import("../content/Soporte/Soporte"));


const AppRouter = () => {
  const [darkMode, setDarkMode] = useState(false);
  const theme = useMemo(() => getTheme(darkMode), [darkMode]);

  const handleThemeChange = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  return (
    <Suspense fallback={<></>}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Dashboard />}>
              {/* rutas hijas */}
              <Route index element={<Inicio />} />
              <Route path="menu/quinesSomos" element={<QuinesSomos />} />
              <Route path="menu/Equipos" element={<Equipos />} />
              <Route path="menu/DetallesEquipos/:id" element={<DetallesEquipos />} />
              <Route path="menu/Soporte" element={<Soporte />} />
              <Route path="menu/inforest" element={<InfoRest />} />
              <Route path="menu/infohotel" element={<InfoHotel />} />
              <Route path="menu/infoback" element={<InfoBack />} />
              <Route path="menu/infofact" element={<InfoFact />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </Suspense>
  );
};

export default AppRouter;