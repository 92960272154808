import  { FC } from "react";
import { Box, Typography } from "@mui/material";

interface Props {
  icon: string;
  title: string;
  description: string;
}

const CardBeneficios: FC<Props> = ({ icon, title, description }) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "280px",
        flexDirection: "column",
        alignItems: "center",
        gap: "32px",
      }}
    >
      {/* Botón Icono */}
      <Box
        sx={{
          display: "flex",
          width: "60px",
          height: "60px",
          padding: "15px",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "24px",
          background: "#FFF",
          boxShadow: "0px 2px 10px 0px rgba(30, 41, 71, 0.14)",
        }}
      >
        <Box
          component={"img"}
          sx={{
            width: "33px",
            height: "33px",
            display: "flex",
            alignItems: "center",
            WebkitMaskImage: `url(${icon})`,
            WebkitMaskSize: "33px",
            WebkitMaskRepeat: "no-repeat",
            WebkitMaskPosition: "center",
            backgroundColor: "#3677DE",
          }}
        />
      </Box>
      {/* Frame 202 */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          alignSelf: "stretch",
        }}
      >
        <Typography
          sx={{
            alignSelf: "stretch",
            textAlign: "center",
            fontFamily: "Montserrat",
            fontSize: "20px",
            fontWeight: 400,
            lineHeight: "120%",
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            height: "78px",
            alignSelf: "stretch",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: 300,
            lineHeight: "150%",
          }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default CardBeneficios;
