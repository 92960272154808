//FOOTER
import PoweredBy from "../assets/Icons/FOOTER/POWERED_BY_131x28.svg";
import Facebook from "../assets/Icons/FOOTER/RSFacebook.svg";
import Instagram from "../assets/Icons/FOOTER/RSInstagram.svg";
import Linkedin from "../assets/Icons/FOOTER/RSLinkedin.svg";
import TikTok from "../assets/Icons/FOOTER/RSTikTok.svg";
import FlechaArriba from "../assets/Icons/FOOTER/FLECHA_ARRIBA_24X24.svg";
import FlechaAbajo from "../assets/Icons/FOOTER/FLECHA_ABAJO_24X24.svg";

//LOGO
import LogoAmc from "../assets/logos/AMC_186X60.svg";
import LogoInfoback from "../assets/logos/INFOBACK.svg";
import LogoInfoFact from "../assets/logos/INFOFACT.svg";
import LogoInfoHotel from "../assets/logos/INFOHOTEL.svg";
import LogoInfoRest from "../assets/logos/INFOREST.svg";
import LogoInfomaticaColorActivo from "../assets/logos/LOGO_INFOMATICA_COLOR_ACTIVO_200X70.svg";
import LogoInfomaticaColorHover from "../assets/logos/LOGO_INFOMATICA_COLOR_HOVER.svg";
import LogoInfomaticaActivo from "../assets/logos/LOGO_INFOMATICA_ACTIVO_200X70.svg";
import LogoInfomaticaHover from "../assets/logos/LOGO_INFOMATICA_HOVER_200X70.svg";
import LogoInfomaticaTrasparente from "../assets/logos/LOGO_INFOMATICA_TRASPARENTE.svg";
import LogoInfomaticaTexto from "../assets/logos/LOGO_INFOMATICA_TEXTO_193X70.svg";
//BODY
import Bares from "../assets/Icons/BODY/BARES_28X28.svg";
import Cafeteria from "../assets/Icons/BODY/CAFETERIAS_28X28.svg";
import Hoteles from "../assets/Icons/BODY/HOTELES_28X28.svg";
import Pastelerias from "../assets/Icons/BODY/PASTELERIAS_28X28.svg";
import Restaurantes from "../assets/Icons/BODY/RESTAURANTES_28X28.svg";
import FlechaDerecha from "../assets/Icons/BODY/FLECHA_DERECHA_22X22.svg";
import FlechaIzquierda from "../assets/Icons/BODY/FLECHA_IZQUIERDA_22X22.svg";
import FlechaLinealDerecha from "../assets/Icons/BODY/FLECHA_LINEAL_DERECHA_24X24.svg";
import FlechaLinealArriba from "../assets/Icons/BODY/FLECHA_LINEAL_ARRIBA_24X24.svg";
import Separador from "../assets/Icons/BODY/SEPARADOR_180X0.svg";
import Summum2023 from "../assets/Icons/BODY/SUMMUM23_200X126.svg";
import Comillas from "../assets/Icons/BODY/COMILLAS_120x104.svg";
import FechaLinealAbajo from "../assets/Icons/BODY/FLECHA_LINEAL_ABAJO_24X4.svg";
import Mensaje from "../assets/Icons/BODY/MENSAJE_32X32.svg";
import TabFlecha from "../assets/Icons/BODY/TABFLECHA_120X8.svg";

//FORMULARIO
import Alerta from "../assets/Icons/FORMULARIO/ALERTA_24X24.svg";
import Contrasena from "../assets/Icons/FORMULARIO/CONTRASENA_ACTIVO_24X24.svg";
import Correo from "../assets/Icons/FORMULARIO/CORREO_24X24.svg";
import OjoMostrar from "../assets/Icons/FORMULARIO/OJO_MOSTRAR_24X24.svg";
import OjoOcultar from "../assets/Icons/FORMULARIO/OJO_OCULTAR_24X24.svg";
import Tienda from "../assets/Icons/FORMULARIO/TIENDA_24X24.svg";
import Usuario from "../assets/Icons/FORMULARIO/USUARIO_ACTIVO_24X24.svg";
import Checkbox from "../assets/Icons/FORMULARIO/CHECKBOX_WHILE_24X24.svg";
import Cerrar from "../assets/Icons/FORMULARIO/CERRAR_ACTIVO_24X24.svg";
import Enviar from "../assets/Icons/FORMULARIO/ENVIAR_MENSAJE_24X24.svg";

//LOGOS CLIENTE
import AMORAMAR from "../assets/Icons/LOGOSCLIENTES/AMORAMAR.svg";
import ANDEANWINGS from "../assets/Icons/LOGOSCLIENTES/ANDEANWINGS.svg";
import ASTRID from "../assets/Icons/LOGOSCLIENTES/ASTRID.svg";
import BOULEVARD from "../assets/Icons/LOGOSCLIENTES/BOULEVARD.svg";
import BRITANIA from "../assets/Icons/LOGOSCLIENTES/BRITANIA.svg";
import CENTRAL from "../assets/Icons/LOGOSCLIENTES/CENTRAL.svg";
import CLUBCUSCO from "../assets/Icons/LOGOSCLIENTES/CLUBCUSCO.svg";
import COSTANERA from "../assets/Icons/LOGOSCLIENTES/COSTANERA.svg";
import DMHOTELES from "../assets/Icons/LOGOSCLIENTES/DMHOTELES.svg";
import DUNAS from "../assets/Icons/LOGOSCLIENTES/DUNAS.svg";
import FIESTA from "../assets/Icons/LOGOSCLIENTES/FIESTA.svg";
import MAIDO from "../assets/Icons/LOGOSCLIENTES/MAIDO.svg";
import MERCADO from "../assets/Icons/LOGOSCLIENTES/MERCADO.svg";
import OSAKA from "../assets/Icons/LOGOSCLIENTES/OSAKA.svg";
import RAFAEL from "../assets/Icons/LOGOSCLIENTES/RAFAEL.svg";
import ROOSEVELT from "../assets/Icons/LOGOSCLIENTES/ROOSEVELT.svg";
import TALLANES from "../assets/Icons/LOGOSCLIENTES/TALLANES.svg";
import TAMBO from "../assets/Icons/LOGOSCLIENTES/TAMBO.svg";

//SOPOTE ICONS
import Comercio from "../assets/Icons/SOPORTE/COMERCIO_28X28.svg";
import Configuracion from "../assets/Icons/SOPORTE/CONFIGURACION_28X28.svg";
import LogoZohoDesk from "../assets/Icons/SOPORTE/LOGOZOHODESK.svg";

//EQUIPO ICONS
import Accesibilidad from "../assets/Icons/EQUIPOS/ACCESIBILIDAD_35X39.svg";
import Group3 from "../assets/Icons/EQUIPOS/GROUP3_42X41.svg";
import Postac from "../assets/Icons/EQUIPOS/POSTACTIL_60X60.svg";
import Procesador from "../assets/Icons/EQUIPOS/PROCESADOR_60X60.svg";
import Tamaño from "../assets/Icons/EQUIPOS/TAMAÑO_33X33.svg";
import Usb from "../assets/Icons/EQUIPOS/USB_60X60.svg";

//QUIENES SOMOS ICONS
import BanderaCheck from "../assets/Icons/QUIENESSOMOS/BANDERA_CHECK_34X34.svg";
import Estrella from "../assets/Icons/QUIENESSOMOS/ESTRELLA_34X34.svg";
import FavoritoCheck from "../assets/Icons/QUIENESSOMOS/CORAZON_FAVORITO_CHECK_34X34.svg";
import GraficoLinea from "../assets/Icons/QUIENESSOMOS/GRAFICOS_LINEAL_34X34.svg";
import ManoRespeto from "../assets/Icons/QUIENESSOMOS/MANO_RESPETO_34X34.svg";
import TiempoReloj from "../assets/Icons/QUIENESSOMOS/TIEMPO_RELOJ.svg";
import UsuarioGrupo from "../assets/Icons/QUIENESSOMOS/USUARIO_GRUPO_34X34.svg";
import LineVertical from "../assets/Icons/QUIENESSOMOS/LINEA_VERTICAL_1652X0.svg";
import MapaMundo from "../assets/Icons/QUIENESSOMOS/MAPA_1920x800.svg";

//IMAGENES NOSOTROS
import Capacitacion from "../assets/Images/NOSOTROS/CAPACITACION_100X100.webp";
import Experiencia from "../assets/Images/NOSOTROS/EXPERIENCIA_100X100.webp";
import Laboral from "../assets/Images/NOSOTROS/LABORAL_100X100.webp";
import Proyecto from "../assets/Images/NOSOTROS/PROYECTO_100X100.webp";

//IMAGEN BANNER
import BannerSummunPC from "../assets/Images/BANNER/BannerSummum.webp";
import BannerSummunMovil from "../assets/Images/BANNER/BannerSummunMovil.webp";
import BannerSummunTable from "../assets/Images/BANNER/BannerSummunTablet.webp";

//IMAGEN SOLUCIONES
import SolucionesAMC from "../assets/Images/SOLUCIONES/AMC.webp";
import SolucionesINFOback from "../assets/Images/SOLUCIONES/INFOBACK.webp";
import SolucionesINFOfact from "../assets/Images/SOLUCIONES/INFOFACT.webp";
import SolucionesINFOhotel from "../assets/Images/SOLUCIONES/INFOHOTEL.webp";
import SolucionesINFOrest from "../assets/Images/SOLUCIONES/INFOREST.webp";

//IMAGEN EQUIPOS
import ImpresoraAMC from "../assets/Images/EQUIPOS/IMPRESORA_AMC_A6_387X387.webp";
import AllInOneAMC1002 from "../assets/Images/EQUIPOS/ALL_IN_ONE_AMC_1002_WP_387x387.webp";
import AllInOneAMC1520 from "../assets/Images/EQUIPOS/ALL_IN_ONE_AMC_1520_387x387.webp";
import PanelAMC from "../assets/Images/EQUIPOS/PANEL_AMC_A6_387X387.webp";
import TotemAMC from "../assets/Images/EQUIPOS/TOTEM_AMC_387x387.webp";

//IMAGEN BODY
import Fondo from "../assets/Images/BODY/FONDO_HEADER_1920X960.webp";
import Hero1 from "../assets/Images/BODY/HERO_1_632x632.webp";
import Hero2 from "../assets/Images/BODY/HERO_2_632x632.webp";
import Hero3 from "../assets/Images/BODY/HERO_3_632x632.webp";
import Producto from "../assets/Images/BODY/PRODUCTOS_774X720.webp";

//IMAGEN SOPORTE
import Soporte from "../assets/Images/SOPORTE/SOPORTE_480X360.webp";

//IMAGEN INFORES
import CartaDigital from "../assets/Images/INFOREST/CARTA_DIGITAL_420X420.webp";
import MobileInfores from "../assets/Images/INFOREST/MOB_INFOREST_420X420.webp";
import Totem from "../assets/Images/INFOREST/TOTEM_420X420.webp";
import Equipos from "../assets/Images/INFOREST/EQUIPOS_1000X400.webp";

// IMAGEN QUINESSOMOS
import BonBeff from "../assets/Images/QUIENESSOMOS/BON_BEEF_SAN_MIGUEL_640X480.webp";
import PedidosMoso from "../assets/Images/QUIENESSOMOS/PEDIDOS_MOSO_1200X480.webp";
import BackOffice from "../assets/Images/QUIENESSOMOS/BACK_OFFICE_280X210.webp";
import Infomatica from "../assets/Images/QUIENESSOMOS/INFOMATICA_1200X480.webp";
import Inforest from "../assets/Images/QUIENESSOMOS/INFO_REST_280X210.webp";
import Informate from "../assets/Images/QUIENESSOMOS/INFO_RMATE_1200X480.webp";
import TurbosysSRL from "../assets/Images/QUIENESSOMOS/TURBOSYS_SRL_280X210.webp";
import TurboSYS from "../assets/Images/QUIENESSOMOS/TURBO_SYS_280X210.webp";
import TsHotel from "../assets/Images/QUIENESSOMOS/TS_HOTEL_280X210.webp";

//LOGO IMAGENES
import Cosme from "../assets/Images/lOGOS/TESTIMONIO_COSME_160X160.webp";
import ElMercado from "../assets/Images/lOGOS/TESTIMONIO_EL_MERCADO_160X160.webp";
import LaMar from "../assets/Images/lOGOS/TESTIMONIO_LA_MAR_160X160.webp";
import Rafael from "../assets/Images/lOGOS/TESTIMONIO_RAFAEL_160X160.webp";

// TESTIMONIO IMAGENES
import LocalCosme from "../assets/Images/TESTIMONIO/TESTIMONIO_COSME.webp";
import LocalLaMar from "../assets/Images/TESTIMONIO/TESTIMONIO_LA MAR.webp";
import LocalMercado from "../assets/Images/TESTIMONIO/TESTIMONIO_MERCADO.webp";
import LocalRafael from "../assets/Images/TESTIMONIO/TESTIMONIO_RAFAEL.webp";

//DETALLES EQUIPOS
import DetalleImpresora1 from "../assets/Images/DETALLESEQUIPOS/IMPRESORA/IMPRESORA1.webp";
import DetalleImpresora2 from "../assets/Images/DETALLESEQUIPOS/IMPRESORA/IMPRESORA2.webp";
import DetalleImpresora3 from "../assets/Images/DETALLESEQUIPOS/IMPRESORA/IMPRESORA3.webp";
import DetalleImpresora4 from "../assets/Images/DETALLESEQUIPOS/IMPRESORA/IMPRESORA4.webp";
import DetalleImpresora5 from "../assets/Images/DETALLESEQUIPOS/IMPRESORA/IMPRESORA5.webp";
import DetalleImpresora6 from "../assets/Images/DETALLESEQUIPOS/IMPRESORA/IMPRESORA6.webp";
import DetalleImpresora7 from "../assets/Images/DETALLESEQUIPOS/IMPRESORA/IMPRESORA7.webp";
import DetalleImpresora8 from "../assets/Images/DETALLESEQUIPOS/IMPRESORA/IMPRESORA8.webp";

//MODULOS
import ADMCentralizada from "../assets/Images/MODULOS/ADM_CENTRALIZADA.webp";
import ADMReglasNegocio from "../assets/Images/MODULOS/ADM_Y_REGLAS_NEGOCIO.webp";
import CajaWeb from "../assets/Images/MODULOS/CAJA_WEB.webp";
import CartaGourmet from "../assets/Images/MODULOS/CARTA_GOURMET.webp";
import CentralDelivery from "../assets/Images/MODULOS/CENTRAL_DELIVERY.webp";
import ChefControl from "../assets/Images/MODULOS/CHEF_CONTROL.webp";
import ConsolaAgregadores from "../assets/Images/MODULOS/CONSOLA_AGREGADORES.webp";
import ConsultaReportes from "../assets/Images/MODULOS/CONSULTA_REPORTES.webp";
import KDS from "../assets/Images/MODULOS/KDS.webp";
import PuntoAdicion from "../assets/Images/MODULOS/PUNTO_ADICION.webp";
import PuntoVentaCaja from "../assets/Images/MODULOS/PUNTO_VENTA_CAJA.webp";
import QIOSCO from "../assets/Images/MODULOS/QIOSCO.webp";

//HEADER
import Carrito from "../assets/Icons/HEADER/CARRITO_24X24.svg";
import Menu from "../assets/Icons/HEADER/MENU_32X32.svg";

//PHOTOS
import UserVentas from "../assets/photos/USER_VENTAS_24X24.png";
import Whatsapp from "../assets/photos/WHATSAPP_200x200.svg";

const Icons = {
  //FOOTER
  PoweredBy: PoweredBy,
  Facebook: Facebook,
  Instagram: Instagram,
  Linkedin: Linkedin,
  TikTok: TikTok,
  flechaArriba: FlechaArriba,
  flechaAbajo: FlechaAbajo,

  //BODY
  bares: Bares,
  cafeteria: Cafeteria,
  hoteles: Hoteles,
  pastelerias: Pastelerias,
  restaurantes: Restaurantes,
  separador: Separador,
  flechaDerecha: FlechaDerecha,
  flechaIzquierda: FlechaIzquierda,
  flechaLinealDerecha: FlechaLinealDerecha,
  flechaLinealArriba: FlechaLinealArriba,
  checkbox: Checkbox,
  summum2023: Summum2023,
  comillas: Comillas,
  fechaLinealAbajo: FechaLinealAbajo,
  mensaje: Mensaje,
  tabFlecha: TabFlecha,

  //FORMULARIO
  alerta: Alerta,
  contrasena: Contrasena,
  correo: Correo,
  ojoMostrar: OjoMostrar,
  ojoOcultar: OjoOcultar,
  tienda: Tienda,
  usuario: Usuario,
  cerrar: Cerrar,
  enviar:Enviar,

  //LOGOS CLIENTE
  Amoramat: AMORAMAR,
  Andeanwings: ANDEANWINGS,
  Astrid: ASTRID,
  Boulevard: BOULEVARD,
  Britania: BRITANIA,
  Central: CENTRAL,
  Clubcusco: CLUBCUSCO,
  Costanera: COSTANERA,
  Dmhotes: DMHOTELES,
  Dunas: DUNAS,
  Fiesta: FIESTA,
  Maido: MAIDO,
  Mercado: MERCADO,
  Osaka: OSAKA,
  Rafael: RAFAEL,
  Roosevelt: ROOSEVELT,
  Tallanes: TALLANES,
  Tambo: TAMBO,

  //EQUIPOS
  accesibilidad: Accesibilidad,
  group3: Group3,
  postac: Postac,
  procesador: Procesador,
  tamaño: Tamaño,
  usb: Usb,

  //SOPORTE
  comercio: Comercio,
  configuracion: Configuracion,
  logoZohoDesk: LogoZohoDesk,

  //QUIENES SOMOS
  BanderaCheck: BanderaCheck,
  Estrella: Estrella,
  FavoritoCheck: FavoritoCheck,
  GraficoLinea: GraficoLinea,
  ManoRespeto: ManoRespeto,
  TiempoReloj: TiempoReloj,
  UsuarioGrupo: UsuarioGrupo,
  lineVertical: LineVertical,
  MapaMundo: MapaMundo,

  //HEADER
  logo: Carrito,
  menu: Menu,

  //PHOTOS
  userVentas:UserVentas,
  whatsapp:Whatsapp

};

const LottiesIcons = {};

const LogoIcons = {
  logoAmc: LogoAmc,
  logoInfoback: LogoInfoback,
  logoInfoFact: LogoInfoFact,
  logoInfoHotel: LogoInfoHotel,
  logoInfoRest: LogoInfoRest,
  logoInfomaticaColorActivo: LogoInfomaticaColorActivo,
  logoInfomaticaColorHover: LogoInfomaticaColorHover,
  logoInfomaticaActivo: LogoInfomaticaActivo,
  logoInfomaticaHover: LogoInfomaticaHover,
  logoInfomaticaTexto: LogoInfomaticaTexto,
  logoInfomaticaTrasparente: LogoInfomaticaTrasparente,
};

const Imagen = {
  capacitacion: Capacitacion,
  experiencia: Experiencia,
  laboral: Laboral,
  proyecto: Proyecto,
  //BANNER
  bannerSummunPC: BannerSummunPC,
  bannerSummunMovil: BannerSummunMovil,
  bannerSummunTable: BannerSummunTable,
  //SOLUCIONES
  solucionesAMC: SolucionesAMC,
  solucionesINFOback: SolucionesINFOback,
  solucionesINFOfact: SolucionesINFOfact,
  solucionesINFOhotel: SolucionesINFOhotel,
  solucionesINFOrest: SolucionesINFOrest,
  //BODY
  fondo: Fondo,
  hero1: Hero1,
  hero2: Hero2,
  hero3: Hero3,
  producto: Producto,
  //EQUIPOS
  impresoraAMC: ImpresoraAMC,
  allInOneAMC1002: AllInOneAMC1002,
  allInOneAMC1520: AllInOneAMC1520,
  panelAMC: PanelAMC,
  totemAMC: TotemAMC,
  //SOPORTE
  soporte: Soporte,
  //IMAGEN INFORES
  CartaDigital: CartaDigital,
  MobileInfores: MobileInfores,
  Totem: Totem,
  equipos: Equipos,
  //IMAGEN QUIONES SOMOS
  bonBeff: BonBeff,
  pedidosMoso: PedidosMoso,
  backOffice: BackOffice,
  infomatica: Infomatica,
  inforest: Inforest,
  informate: Informate,
  tsHotel: TsHotel,
  turbosysSRL: TurbosysSRL,
  turboSYS: TurboSYS,
  //LOGO IMAGEN
  cosme: Cosme,
  elMercado: ElMercado,
  laMar: LaMar,
  rafael: Rafael,
  // TESTIMONIO
  localCosme: LocalCosme,
  localLaMar: LocalLaMar,
  localMercado: LocalMercado,
  localRafael: LocalRafael,
  //DETALLESEQUPO
  detalleImpresora1: DetalleImpresora1,
  detalleImpresora2: DetalleImpresora2,
  detalleImpresora3: DetalleImpresora3,
  detalleImpresora4: DetalleImpresora4,
  detalleImpresora5: DetalleImpresora5,
  detalleImpresora6: DetalleImpresora6,
  detalleImpresora7: DetalleImpresora7,
  detalleImpresora8: DetalleImpresora8,
  //MODULOS
  admCentralizada: ADMCentralizada,
  admReglasNegocio: ADMReglasNegocio,
  cajaWeb: CajaWeb,
  cartaGourmet: CartaGourmet,
  centralDelivery: CentralDelivery,
  chefControl: ChefControl,
  consolaAgregadores: ConsolaAgregadores,
  consultaReportes: ConsultaReportes,
  kds: KDS,
  puntoAdicion: PuntoAdicion,
  puntoVentaCaja: PuntoVentaCaja,
  qiosco: QIOSCO,
};

const lightColors = {
  Pasivo: "#FFFFFF",
  Hover: "#F7A43F",
  Activo: "#4884E2",
  //Gris
  Neutro: "#88919E",
  TitleGris: "#717883",
  //colores claros
  ClaroHover: "#EEF5FC",
  Claro: "#FFF5F0",
  //Alertas
  ErrorPasivo: "#FF655A",
  ErrorHover: "#F44336",
  ErrorHoverClaro: "#FFECEB",
  Exito: "#42C67E",
  Informacion: "#2D94B4",
  //texto
  Title: "#E46525",
  TitleHover: "#FCA422",
  //lINEA
  lineaModal: "#4884E2",
  lineaTabla: "#FBD6C5",
  //circulos
  Circular: "#FEEBE2",
};

const darkColors = {
  Pasivo: "#121212",
  Hover: "#F7A43F",
  Activo: "#F07B43",
  //Gris
  Neutro: "#88919E",
  TitleGris: "#717883",
  //Claro
  ClaroHover: "#FFFAF7",
  Claro: "#FFF5F0",
  //Alertas
  ErrorPasivo: "#FF655A",
  ErrorHover: "#F44336",
  ErrorHoverClaro: "#FFECEB",
  Exito: "#42C67E",
  Informacion: "#2D94B4",
  //text
  Title: "#E46525",
  TitleHover: "#FCA422",
  //lINEA
  lineaModal: "#4884E2",
  lineaTabla: "#FBD6C5",
  //circulos
  Circular: "#FEEBE2",
};

export const themes = {
  light: {
    icons: Icons,
    colors: lightColors,
    Lotties: LottiesIcons,
    logo: LogoIcons,
    imagen: Imagen,
  },
  dark: {
    icons: Icons,
    colors: darkColors,
    Lotties: LottiesIcons,
    logo: LogoIcons,
    imagen: Imagen,
  },
};
