import { FC, useState } from "react";
import { Box, Typography } from "@mui/material";
import ButtonFlecha from "../Buttones/ButtonFlecha";

interface Props {
  fondo: string;
  title: string;
  logo: string;
  iconflecha: string;
  descripcion: string;
  onClick: () => void;
  maxWidth?: any;
}

const CardSoluciones: FC<Props> = ({
  fondo,
  title,
  logo,
  iconflecha,
  descripcion,
  onClick,
  maxWidth,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%", // Ancho completo del contenedor padre
        maxWidth: maxWidth,
        height: "280px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "8px",
        background: `url(${fondo})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
        overflow: "hidden",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Contenido principal */}
      <Box
        sx={{
          display: "flex",
          padding: "10px 20px",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "8px",
          flex: "1 0 0",
          alignSelf: "stretch",
          background: isHovered ? "" : "rgba(237, 244, 253, 0.60)",
        }}
      >
        <Box
          sx={{
            display: isHovered ? "none" : "flex",
            width: "241.778px",
            height: "64px",
            padding: "16px 16px 14.4px 16px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/*LOGO DE INFOrest */}
          <Box component={"img"} src={logo} />
        </Box>
      </Box>

      {/* Fondo negro (aparece en hover) */}
      {isHovered && (
        <Box
          sx={{
            display: "flex",
            padding: "0px 20px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
            flex: "1 0 0",
            alignSelf: "stretch",
            background: " rgba(35, 35, 35, 0.70)",
            position: "absolute", // Asegura que el fondo se superponga al contenido
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1, // Asegura que el fondo esté encima del contenido
          }}
        >
          {/* INFO */}
          <Box
            sx={{
              display: "flex",
              paddingTop: "20px",
              flexDirection: "column",
              alignItems: "center",
              gap: "8px",
              alignSelf: "stretch",
            }}
          >
            <Typography
              sx={{
                color: "#FFF",
                textAlign: "center",
                fontFamily: "Montserrat",
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "120%",
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                color: "#FFF",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: 300,
                lineHeight: "150%",
              }}
            >
              {descripcion}
            </Typography>
          </Box>
          <ButtonFlecha
            onClick={onClick}
            textColor={"#FFF"}
            textName={"¡Me interesa!"}
            iconName={iconflecha}
            bgColor={"#FFF"}
          />
        </Box>
      )}
    </Box>
  );
};

export default CardSoluciones;
