import { Box, Typography } from "@mui/material";
import { FC } from "react";
import ButtonText from "../../components/Buttones/ButtonText";
import { themes } from "../../config/Icons";
import CardSoluciones from "../../components/Card/CardSoluciones";
import { useNavigate } from "react-router-dom";
import generateSoluciones from "../../models/functions/generateSoluciones";

interface Props {
  isDarkMode?: Boolean;
}

const SolucionesEquipo: FC<Props> = ({ isDarkMode }) => {
  const themeMode = isDarkMode ? themes.dark : themes.light;
  const navigate = useNavigate();
  const solucionesDataEquipos = generateSoluciones("equipos", themeMode);
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        padding: "100px 0px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "60px",
        background: "#F8F9FC",
      }}
    >
      {/*servios */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "40px",
        }}
      >
        {/*titulo */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "24px",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontFamily: "Montserrat",
              fontSize: "36px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "120%",
            }}
          >
            Descubre soluciones completas y eficientes
          </Typography>
          {/* Separador */}
          <Box component={"img"} src={themeMode.icons.separador} />
        </Box>
        {/*opc */}
        <Box
          sx={{
            display: "flex",
            width: "1200px",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: "12px",
          }}
        >
          {solucionesDataEquipos.map((solucion, index) => (
            <CardSoluciones
              onClick={() => {
                window.scrollTo(0, 0);
                navigate(solucion.link);
              }}
              key={index}
              {...solucion}
              maxWidth="300px"
            />
          ))}
        </Box>
      </Box>

      {/*botones texto */}
      <ButtonText
        height="56px"
        width="360px"
        onClick={() => {}}
        title="Pregunta por nuestros servicios"
        active={true}
        bgColor={"#4884E2"}
        hoverColor={"#69C4DE"}
        fontSize={15}
      />
    </Box>
  );
};

export default SolucionesEquipo;
