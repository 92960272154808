import { Solucion } from "../inicio/SolucionModel";

export default function generateSoluciones(type: string, themeMode: any): Solucion[] {
  if (type === "inicio") {
   
     return [
      {
        fondo: themeMode.imagen.solucionesINFOrest,
        logo: themeMode.logo.logoInfoRest,
        iconflecha: themeMode.icons.flechaLinealDerecha,
        title: "INFOrest",
        descripcion: "150 caracteres máx. Lorem ipsum dolore sit amet, consecte tu adicing elit.",
        link: "/menu/inforest",
      },
      {
        fondo: themeMode.imagen.solucionesINFOhotel,
        logo: themeMode.logo.logoInfoHotel,
        iconflecha: themeMode.icons.flechaLinealDerecha,
        title: "INFOhotel",
        descripcion: "150 caracteres máx. Lorem ipsum dolore sit amet, consecte tu adicing elit.",
        link: "/menu/infohotel",
      },
      {
        fondo: themeMode.imagen.solucionesINFOback,
        logo: themeMode.logo.logoInfoback,
        iconflecha: themeMode.icons.flechaLinealDerecha,
        title: "INFOback",
        descripcion: "150 caracteres máx. Lorem ipsum dolore sit amet, consecte tu adicing elit.",
        link: "/menu/infoback",
      },
      {
        fondo: themeMode.imagen.solucionesAMC,
        logo: themeMode.logo.logoAmc,
        iconflecha: themeMode.icons.flechaLinealDerecha,
        title: "AMC",
        descripcion: "150 caracteres máx. Lorem ipsum dolore sit amet, consecte tu adicing elit.",
        link: "/menu/Equipos",
      },
      {
        fondo: themeMode.imagen.solucionesINFOfact,
        logo: themeMode.logo.logoInfoFact,
        iconflecha: themeMode.icons.flechaLinealDerecha,
        title: "INFOfact",
        descripcion: "150 caracteres máx. Lorem ipsum dolore sit amet, consecte tu adicing elit.",
        link: "/menu/infofact",
      },
    ];
   
  } else {
     return [
      {
        fondo: themeMode.imagen.solucionesINFOrest,
        logo: themeMode.logo.logoInfoRest,
        iconflecha: themeMode.icons.flechaLinealDerecha,
        title: "INFOrest",
        descripcion: "150 caracteres máx. Lorem ipsum dolore sit amet, consecte tu adicing elit.",
        link: "/menu/inforest",
      },
      {
        fondo: themeMode.imagen.solucionesINFOhotel,
        logo: themeMode.logo.logoInfoHotel,
        iconflecha: themeMode.icons.flechaLinealDerecha,
        title: "INFOhotel",
        descripcion: "150 caracteres máx. Lorem ipsum dolore sit amet, consecte tu adicing elit.",
        link: "/menu/infohotel",
      },
      {
        fondo: themeMode.imagen.solucionesINFOback,
        logo: themeMode.logo.logoInfoback,
        iconflecha: themeMode.icons.flechaLinealDerecha,
        title: "INFOback",
        descripcion: "150 caracteres máx. Lorem ipsum dolore sit amet, consecte tu adicing elit.",
        link: "/menu/infoback",
      },
      {
        fondo: themeMode.imagen.solucionesINFOfact,
        logo: themeMode.logo.logoInfoFact,
        iconflecha: themeMode.icons.flechaLinealDerecha,
        title: "INFOfact",
        descripcion: "150 caracteres máx. Lorem ipsum dolore sit amet, consecte tu adicing elit.",
        link: "/menu/infofact",
      },
    ];
  }
}
