import { FC, useState } from "react";
import { Box, Typography } from "@mui/material";

interface Props {
  onClick: () => void;
  textColor: string;
  textName: string;
  bgColor: string;
  iconName: any;
}

const ButtonFlecha: FC<Props> = ({
  onClick,
  bgColor,
  iconName,
  textColor,
  textName,
}) => {
  const [isHovered, setIsHovered] = useState(false); // Estado para hover

  const handleMouseEnter = () => {
    setIsHovered(true); // Activa el estado de hover
  };

  const handleMouseLeave = () => {
    setIsHovered(false); // Desactiva el estado de hover
  };

  const handleClick = () => {
    onClick();
  };

  return (
    <Box
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        display: "flex",
        height: "48px",
        padding: "0px 4px",
        alignItems: "center",
        gap: "4px",
        cursor: "pointer",
      }}
    >
      <Typography
        sx={{
          overflow: "hidden",
          color: textColor,
          textOverflow: "ellipsis",
          fontFamily: "Poppins",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "20px",
          textDecorationLine: "underline",
        }}
      >
        {textName}
      </Typography>

      {/* Contenedor para la imagen */}
      <Box
        sx={{
          width: "24px", 
          height: "24px",
          overflow: "hidden", // Oculta lo que crece fuera del contenedor
        }}
      >
        
        <Box component={"img"}
          sx={{
            width: "24px", 
            height: "24px",
            transformOrigin: "left",
            transform: isHovered ? "scaleX(1.3)" : "scaleX(1)", // Expande hacia atrás
            transition: "transform 0.1s ease", // Transición suave
            WebkitMaskImage: `url(${iconName})`, // Cambia a una máscara simple para prueba
            WebkitMaskSize: "24px", // Ajusta según sea necesario
            WebkitMaskRepeat: "no-repeat",
            WebkitMaskPosition: "center",
            backgroundColor: bgColor, 
          }}
        />
      </Box>
    </Box>
  );
};

export default ButtonFlecha;
