import React from "react";
import { Box, Typography } from "@mui/material";
import ButtonText from "../Buttones/ButtonText";

interface Props {
  image: string;
  title: string;
  description: string;
  onButtonClick: () => void;
}

const CardComplementos: React.FC<Props> = ({
  image,
  title,
  description,
  onButtonClick,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "40px",
        alignSelf: "stretch",
      }}
    >
      {/* Complemento */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          width: "1200px",
          height: "460px",
          position: "relative",
        }}
      >
        {/* Imagen */}
        <Box
          component="img"
          src={image}
          sx={{
            position: "absolute",
            right: "40px",
            bottom: "0px",
            width: "420px",
            height: "420px",
          }}
        />
        {/* Contenido */}
        <Box
          sx={{
            display: "flex",
            height: "360px",
            padding: "80px 40px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: "40px",
            flexShrink: 0,
            alignSelf: "stretch",
            borderRadius: "20px",
            background: "#D9E6FA",
          }}
        >
          {/* Texto */}
          <Box
            sx={{
              display: "flex",
              width: "680px",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "20px",
            }}
          >
            <Typography
              sx={{
                alignSelf: "stretch",
                fontFamily: "Montserrat",
                fontSize: "28px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "32px",
              }}
            >
              {title}
            </Typography>

            <Typography
              sx={{
                alignSelf: "stretch",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 300,
                lineHeight: "150%",
              }}
            >
              {description}
            </Typography>
          </Box>
          {/* Botón */}
          <Box
            sx={{
              display: "flex",
              width: "280px",
              height: "56px",
            }}
          >
            <ButtonText
              height="56px"
              width="100%"
              onClick={onButtonClick}
              title={"quiero saber más"}
              active={true}
              bgColor={"#4884E2"}
              hoverColor={"#69C4DE"}
              fontSize={15}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CardComplementos;
