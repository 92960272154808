import { Box, Typography } from "@mui/material";
import { FC } from "react";
import ButtonText from "../Buttones/ButtonText";

interface Props {
  image: any;
  title: string;
  description: string;
  precio: string;
  descuento: string;
  handleOpen: () => void;
}

const CardEquipo: FC<Props> = ({
  image,
  title,
  description,
  precio,
  descuento,
  handleOpen,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        minWidth: "300px",
        flexDirection: "column",
        alignItems: "flex-start",
        flex: "1 0 0",
        borderRadius: "20px",
        boxShadow: "0px 4px 16px 0px rgba(30, 41, 71, 0.12)",
      }}
    >
      {/*IMAGEN */}
      <Box
        component={"img"}
        src={image}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          alignSelf: "stretch",
          borderBottom: "1px solid #E2E5E8",
          width: "100%",
          borderRadius: "20px 20px 0px 0px",
        }}
      />
      {/*contenido */}
      <Box
        sx={{
          display: "flex",
          padding: "28px 24px",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "40px",
          alignSelf: "stretch",
        }}
      >
        {/*textos*/}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "12px",
            alignSelf: "stretch",
          }}
        >
          {/*titulo */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "4px",
            }}
          >
            <Typography
              sx={{
                color: "#1E2947",
                fontFamily: "Montserrat",
                fontSize: "20px",
                fontWeight: 400,
                lineHeight: "120%",
              }}
            >
              {title}
            </Typography>
            {/*precios */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "12px",
              }}
            >
              <Typography
                sx={{
                  color: "#1E2947",
                  fontFamily: "Montserrat",
                  fontSize: "24px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "120%",
                }}
              >
                {precio}
              </Typography>
              <Typography
                sx={{
                  color: "#717883",
                  fontFamily: "Montserrat",
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "120%",
                  textDecoration: "line-through",
                }}
              >
                {descuento}
              </Typography>
            </Box>
          </Box>
          {/*DESCRIPCION */}
          <Typography
            sx={{
              alignSelf: "stretch",
              color: "#717883",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontWeight: 300,
              lineHeight: "150%",
            }}
          >
            {description}
          </Typography>
        </Box>
        {/*botones */}
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: "12px",
            alignSelf: "stretch",
          }}
        >
          <ButtonText
            height="56px"
            width="100%"
            onClick={handleOpen}
            title="me interesa"
            active={true}
            bgColor={"#4884E2"}
            hoverColor={"#69C4DE"}
            fontSize={15}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CardEquipo;
