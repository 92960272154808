import React, { FC, useState } from "react";
import Box from "@mui/material/Box";
import { themes } from "../../config/Icons";
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';

interface Props {
  card: any;
  isDarkMode?: any;
}

const GaleriaEquipos: FC<Props> = ({ isDarkMode, card }) => {
  const themeMode = isDarkMode ? themes.dark : themes.light;
  const cardData = [
    {
      image: themeMode.imagen.detalleImpresora1,
    },
    {
      image: themeMode.imagen.detalleImpresora2,
    },
    {
      image: themeMode.imagen.detalleImpresora3,
    },
    {
      image: themeMode.imagen.detalleImpresora4,
    },
    {
      image: themeMode.imagen.detalleImpresora5,
    },
    {
      image: themeMode.imagen.detalleImpresora6,
    },
  ];
  const [selectedImage, setSelectedImage] = useState(cardData[0].image);
  const [selectedIndex, setSelectedIndex] = useState(0);


  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          position: "relative",
        }}
      >
        {cardData.map((card, index) => (
          <Box
            key={index}
            component={"img"}
            src={card.image}
            sx={{
              display: "flex",
              width: "64px",
              height: "64px",
              padding: "4px 6px",
              alignItems: "center",
              gap: "10px",
              border: "1px solid  #E2E5E8",
              cursor: "pointer",
            }}
            onClick={() => {
              setSelectedImage(card.image);
              setSelectedIndex(index);
            }}
          />
        ))}
        {/*barra lateral */}
        {selectedImage && (
          <Box
            sx={{
              display: "flex",
              width: "2px",
              height: "64px",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "10px",
              position: "absolute",
              background: "#3677DE",
              top: `${selectedIndex * 74}px`, // Ajusta la posición en función del índice
            }}
          />
        )}
      </Box>
      {/*RATIO DE IMAGEN */}
      <Box
        sx={{
          border: "1px solid  #E2E5E8",
          marginLeft: "20px",
          width: "480px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box sx={{ maxWidth: '100%', maxHeight: '100%' }}>
          <InnerImageZoom
            src={selectedImage}
            zoomSrc={selectedImage}
            zoomType="hover"
            zoomPreload={true}
            zoomScale={1.2}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default GaleriaEquipos;
