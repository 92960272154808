import React from "react";
import { Box, Typography } from "@mui/material";

interface Props {
  icon: string;
  text: string;
  textColor?: string;
}


const PropEquipo: React.FC<Props> = ({ icon, text, textColor = "#1E2947" }) => (
  <Box
    sx={{
        display: "flex",
        width: "160px",
        height: "140px",
        padding: "0px 12px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
    }}
  >
    {/* ICONOS */}
    <Box
      sx={{
        display: "flex",
        width: "60px",
        height: "60px",
        justifyContent: "center",
        alignItems: "center",
        flexShrink: 0,
      }}
    >
      <Box
        component="img"
        src={icon}
        sx={{
         // width: "60px",
         // height: "60px",
        }}
      />
    </Box>
    {/* TEXTO */}
    <Typography
      sx={{
        alignSelf: "stretch",
        color: textColor,
        textAlign: "center",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "150%",
      }}
    >
      {text}
    </Typography>
  </Box>
);

export default PropEquipo;
