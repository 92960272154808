import { Box, Button } from "@mui/material";
import { FC, useState } from "react";

interface Props {
  onClick: () => void;
  title?: string;
  active?: boolean;
  propina?: boolean;
  bgColor?: string;
  hoverColor?: string;
  height?: any;
  width: any;
  disabled?: boolean; // Nueva prop para deshabilitar el botón
  fontSize: any;
  icon?: any;
  colorTitle?: any;
  colorHover?: any;
  iconHcolor?: any;
  iconNcolor?: any;
}

const ButtonText: FC<Props> = ({
  onClick,
  title,
  active,
  propina,
  bgColor,
  hoverColor,
  height,
  width,
  fontSize,
  disabled = false, // Valor por defecto para DESBLOQUEO
  icon,
  colorTitle = "white",
  colorHover = "white",
  iconHcolor,
  iconNcolor,
}) => {
  const [isHovered, setIsHovered] = useState(false); // Estado para controlar el hover
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      onMouseEnter={() => setIsHovered(true)} // Cambia el estado al entrar
      onMouseLeave={() => setIsHovered(false)} // Cambia el estado al salir, pero solo si el modal no está abierto
      sx={{
        display: "flex",
        width: width,
        height: height,
        transition: "none",
        color: disabled ? "#F2F2F2" : active ? colorTitle : bgColor,
        backgroundColor: disabled ? "#f0f0f0" : active ? bgColor : "white",
        textTransform: "uppercase",
        fontWeight: 500,
        fontSize: fontSize,
        padding: active ? "none" : "12px  24px",
        lineHeight: "18px",
        textAlign: "center",
        fontFamily: "Poppins",
        gap: "10px",
        fontStyle: "normal",
        borderRadius: "30px",
        border: disabled
          ? "none"
          : active
          ? ""
          : propina
          ? "0px solid"
          : `1px solid ${bgColor}`, // Sin borde cuando está deshabilitado
        textDecorationLine: propina ? "underline" : "",
        boxShadow: active ? "0px 2px 10px 0px rgba(30, 41, 71, 0.14)" : "",
        cursor: disabled ? "not-allowed" : "pointer", // Cambia el cursor si está deshabilitado
        "&:hover": {
          backgroundColor: disabled ? "#f0f0f0" : propina ? "#fff" : hoverColor,
          textDecorationLine: propina ? "underline" : "",
          color: propina ? hoverColor : colorHover,
          border: propina || disabled ? "none" : `1px solid ${hoverColor}`, // Sin borde al hover si está deshabilitado

          boxShadow: active ? "0px 6px 16px 0px rgba(25, 67, 127, 0.32)" : "",
        },
      }}
    >
      {icon && (
        <Box
          component="img"
          sx={{
            width: "24px",
            height: "24px",
            display: "flex",
            alignItems: "center",
            WebkitMaskImage: `url(${icon})`,
            WebkitMaskSize: "24px",
            WebkitMaskRepeat: "no-repeat",
            WebkitMaskPosition: "center",
            backgroundColor: isHovered ? iconHcolor : iconNcolor,
          }}
        />
      )}
      {title}
    </Button>
  );
};

export default ButtonText;
